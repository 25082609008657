import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Sample2 = ({ location }) => {
  const [token, setToken] = useState('');

  // const data = useSelector((state) => state.token.data);

  useEffect(() => {
    console.log('token', token);
    //取得redux參數
    // setToken(data);
    //利用navigate傳遞取得參數
    const value = location.state.data;
    console.log('value', value);
  }, []);

  return (
    <>
      <h1>範例</h1>
      <label>{token}</label>
    </>
  );
};

export default Sample2;
